//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";
import ProductSizes from "@/components/pages/productCard/ProductContent/Components/ProductSizes/index";
import ProductBuyFavorite from "@/components/pages/productCard/ProductContent/Components/ProductBuyFavorite/index";
import ProductPrice from "@/components/pages/productCard/ProductContent/Components/ProductPrice/index";

export default {
    name: "ChooseSize",
    components: {ProductPrice, ProductBuyFavorite, ProductSizes, VPopup},
    props: {
        product: {
            type: Object,
            required: true
        }
    }
}
