//
//
//
//
//
//


import ChooseSize from "@/components/popups/ChooseSize";

export default {
    name: "ProductChooseSize",
    props: {
        product: {
            type: Object,
            required: true,
        }
    },
    methods: {
        showPopup() {
            this.$popups.open({
                component: ChooseSize,
                props: {
                    product: this.product
                }
            })
        }
    },
    mounted() {
        this.$root.$on("showSizePopup", this.showPopup)
    }
}
