
import icons from './images/index'

export default {
    name: "CommonIcon",
    functional: true,
    props: {
        icon: {
            type: String,
            required: true
        }
    },
    render: function (createElement, {data, props}) {
        return createElement('div', {
            domProps: {innerHTML: icons[props.icon]},
            class: [
                'button-back',
                data.staticClass
            ],
        });
    }
}


