//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from '@/components/common/ResponsiveImage';

export default {
    name: "ProductColors",
    components: {
        ResponsiveImage,
    },
    props: {
        product: {
            type: Object,
            default: ()=>{}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        productColors(){
            return (this?.product?.colors ?? [])
        },
        showProductColors() {
            return this.productColors.length >= 1;
        }
    },
    methods: {
        imageUrl(image) {
            return ImageHelper.getMediaStructure(
                image.id,
                AppConfig.image.fileExtensions.productCard,
                (image?.title ?? ''),
                (image?.alt ?? ''),
                ImageSizes.cardThumbnail, 70)
        }
    }
}
