//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"
import CommonIcon from "@/components/common/commonIcon/index";
import SectionProduct from "@/components/common/section/SectionProduct";

export default {
    name: "ProductCare",
    components: {
        SectionProduct,
        CommonIcon
    },
    props: {
        product: {
            type: Object,
            required: true,
        }
    },
    computed: {
        ...mapGetters({
            'hasCare': 'productView/productHasCareBlock',
            'showMainPart': 'productView/productHasMainCareBlock',
            'showExtraPart': 'productView/productHasExtraCareBlock',
        }),
    }
}
