//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"
import SectionProduct from "@/components/common/section/SectionProduct";
import ResponsiveImage from "@/components/common/ResponsiveImage";

export default {
    name: "ProductDescription",
    components: {
        ResponsiveImage,
        SectionProduct
    },
    props: {
        product: {
            type: Object,
            required: true,
        }
    },
    computed: {
        ...mapGetters({
            'hasDescription': 'productView/productHasDescription',
            'showHeadline': 'productView/productHasDescriptionHeadline',
            'showText': 'productView/productHasDescriptionText',
            'showImage': 'productView/productHasDescriptionImage',
        }),
        subtitle() {
            return (this?.product?.description?.subHeadline ?? '')
        },
        description() {
            return (this?.product?.description?.shortDescription ?? '')
        },
        descriptionImage() {
            if (!this.showImage) {
                return null
            }
            let image = this.product.description.descriptionImage
            return ImageHelper.getMediaStructure(image.id, AppConfig.image.fileExtensions.productCard, image.title, image.alt, ImageSizes.cardDescription, 75)
        },
        hasDescriptionImage(){
            return this.showImage !== [] && this.showImage && this.showImage.id && this.showImage.id.length
        }
    }
}
