//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BackButton from "@/components/common/buttons/BackBtn";

export default {
    name: "Breadcrumbs",
    components: {BackButton},
    props: {
        metaBreadcrumb: {
            type: Array,
            required: true
        },
        h1OnLastLevel: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        showAsLink(url) {
            return url && url !== ''
        },
        goBack(){
            this.$router.back()
        }
    }
}
