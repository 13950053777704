//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductPrice from "@/components/pages/productCard/ProductContent/Components/ProductPrice/index";
export default {
    name: "ProductMobileTop",
    components: {
        ProductPrice
    },
    props: {
        product: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        productDisplayName () {
            return (this?.product?.displayName ?? '')
        },
    }
}
