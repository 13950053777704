//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueScrollTo from 'vue-scrollto';
import {mapGetters} from "vuex";

export default {
    name: "ProductNavigations",
    props: {
        product: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            options: {
                container: 'body',
                easing: 'ease-in-out',
                lazy: false,
                offset: -70,
                force: true,
                cancelable: true,
                x: false,
                y: true
            },
        }
    },
    computed: {
        ...mapGetters({
            'menu': 'productView/getProductNavigationItems'
        }),
        showBlock() {
            return this.menu.length > 1
        }
    },
    methods: {
        scrollTo(element) {
            VueScrollTo.scrollTo(element, 500, this.options)
        }
    }
}
