//
//
//
//
//
//
//
//
//
//

import monobank from '~/assets/img/icons/monobank.svg';
import ProductPdp from '@/components/pages/productCard/ProductContent/Components/ProductPdp/index';
import ProductBuyFavorite from '@/components/pages/productCard/ProductContent/Components/ProductBuyFavorite/index';
import ProductSizes from '@/components/pages/productCard/ProductContent/Components/ProductSizes/index';
import SizeAdvice from '@/components/pages/productCard/ProductContent/Components/SizeAdvice';
import ProductInfo from "~/components/pages/productCard/ProductContent/Components/ProductInfo/index.vue";

export default {
    name: "ProductMobileBottom",
    components: {
        ProductInfo,
        ProductPdp,
        ProductBuyFavorite,
        ProductSizes,
        SizeAdvice,
    },
    props: {
        product: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            monobank: monobank,
        }
    },

    computed: {
        computedAdviceData(){
            return this.product?.adviceSize ? this.product?.adviceSize : null;
        },
    }
}
