//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import SectionProduct from "@/components/common/section/SectionProduct";

export default {
    name: "ProductBullets",
    components: {
        SectionProduct
    },
    props: {
        product: {
            type: Object,
            required: true,
        }
    },
    computed:{
        ...mapGetters({
            'hasAbout': 'productView/productHasAboutBlock'
        })
    }
}
